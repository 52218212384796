.mainDiv {
    background-color: #f7f4f2;
    padding-bottom: 50px;
}

.whyTxt {
    font-size:  18px ;
    line-height:  30px ;
    text-align:  justify ;
    font-family:  Georgia ;
}

.educationHR {
    text-align:  center ;
    width:  300px ;
    color:  black ;
}

.educationTxt {
    font-size:  20px ;
    line-height:  30px ;
    text-align:  justify ;
    margin-top:  20px ;
    font-family:  Cambria, Georgia, serif ;
}

.healthHR {
    text-align:  center ;
    width:  300px ;
    color:  black ;
}

.healthContainer {
    background-color:  white ;
    margin-bottom:  50px ;
    padding-bottom: 0px !important ;
    border: 1px solid #e7e4e2 ;
    box-shadow:  2.5px 2.5px #e7e4e2 ; 
}

.healthTxt{
    font-size:  20px ;
    line-height:  30px ;
    text-align:  justify ;
    margin-top:  0px ;
    font-family:  Cambria, Georgia, serif ;
}

.healthTxtTwo {
    font-size:  20px ;
    line-height:  30px ;
    text-align:  justify ;
    margin-top:  20px ;
    font-family:  Cambria, Georgia, serif ;
}

.socialHR {
    text-align:  center ;
    width:  300px ;
    color:  black ;
}

.socialTxt {
    font-size:  20px ;
    line-height:  30px ;
    text-align:  justify ;
    margin-top:  20px ;
    font-family:  Cambria, Georgia, serif ;
}

.economicContainer {
    background-color:  white ;
    margin-bottom:  50px ;
    border: 1px solid #e7e4e2 ;
    box-shadow:  2.5px 2.5px #e7e4e2 ;
    padding-bottom:  0px !important ;
}

.economicTxt {
    font-size:  21px ;
    line-height:  30px ;
    text-align:  justify ;
    margin-top:  0px ;
    font-family:  Cambria, Georgia, serif ;
}

@media (max-width: 768px) {
    .whyTxt{
        font-size: 13px;
        line-height: 20px;
    }
}