.social{
    display: block;
}
.email{
    font-size: 1rem;
    font-weight: bold;
}
.footerFont{
    color: darkgray;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

@media only screen and (max-width: 500px) {
    .social {
        display: none !important;
    }
    .email{
        font-size: 14px !important;
    }
  }