.mainDiv {
    background-color: #f7f5f2;
    padding-bottom: 50px;
}

.aboutProjectsTxt {
    font-size:  20px ;
    line-height:  30px ;
    text-align:  justify ;
    font-family:  Georgia ;
}

.covidDiv {
    display:  flex ;
    flex-direction: column ;
    align-items: center ;
    justify-content: center ;
    margin-top: -45px ;
}

.projectLibraryTxt{
    font-size:  17px ;
    line-height:  20px ;
    text-align:  justify ;
    font-family:  Cambria, Georgia, serif ;
}

.covidHR {
    font-family: 'Merriweather', serif;
}

.covidHRTwo {
    text-align:  center ;
    width:  300px ;      
    color:  black ;        
    margin-top:  0px ;
}

.covidContainer{
    text-align: center ;
    border: 1px solid #e7e4e2 ;
    box-shadow:  2.5px 2.5px #e7e4e2 ;
}

.covidTxt {
    font-size:  15.5px ;
    line-height:  30px ;
    text-align:  justify ;
    margin-top:  -30px ;
    /* TO DO: fix margin-top */
    font-family:  Cambria, Georgia, serif ;  
}

.covidContainerTwo {
    background-color:  white ;
    margin-top: 50px ;
    margin-bottom:  50px ;
    border: 1px solid #e7e4e2 ;
    box-shadow:  2.5px 2.5px #e7e4e2 ;
}

.covidTxtTwo {
    font-size: "20px";
    line-height: "30px";
    text-align: "justify";
    margin-top: "20px";
    font-family: "Cambria, Georgia, serif";
}
.aboutCovid{
    font-size: 16px;
}
.picturesMobile{
    display: block;
}
.marginOfTop{
    margin-left: -20px;
}
.marginOfBottom{
    margin-left: 20px;
}

@media (max-width: 768px) {
    .aboutProjectsTxt{
        font-size: 14px;
        line-height: 20px;
    }
    .picturesMobile{
        display: none;
    }
    .projectLibraryTxt{
        font-size: 14px;
        margin-left: 0px;
    }
    .aboutCovid{
        font-size: 14px;
    }
    .marginOfTop{
        margin-left: 0px;
    }
    .marginOfBottom{
        margin-left: 0px;
    }
}