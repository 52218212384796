blockquote {
  font-family: Georgia, serif;
  font-size: 28px;
  font-style: italic;
  width: 80%;
  margin: auto;
  padding: 0.35em 40px;
  line-height: 1.45;
  position: relative;
  color: #383838;
}

@media screen and (max-width: 500px) {
  blockquote {
    width: 100%;
  }
}

blockquote:before {
  display: block;
  padding-left: 10px;
  content: "\201C";
  font-size: 80px;
  position: absolute;
  left: -20px;
  top: -20px;
  color: #7a7a7a;
}

blockquote cite {
  color: #999999;
  font-size: 14px;
  display: block;
  margin-top: 5px;
  font-size: 24px;
}

blockquote cite:before {
  content: "\2014 \2009";
}
