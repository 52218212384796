.schoolSponsorshipProgramTxt{
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
    font-family:  Georgia ;
}

.projectLibraryContainer{
    display:  flex ;
    flex-direction: column ;
    align-items: center ;
    justify-content: center ;
    margin-top: -45px ;

}
.projectLibraryHR{
    font-family: Merriweather, Serif;
}

.projectLibraryHRTwo{
    text-align:  center ;
    width:  300px ;
    color:  black ;
    margin-top:  0px ;
}

.projectLibraryTxt{
    font-size:  21px ;
    line-height:  20px ;
    text-align:  justify ;
    margin-top:  80px ;
    font-family:  Cambria, Georgia, serif ;
}

.projectLibraryTxtTwo{
    font-size:  16px ;
    line-height:  30px ;
    text-align:  justify ;
    margin-top:  -30px ;
    font-family:  Cambria, Georgia, serif ;
}

.sportsEquipmentHR{
    text-align:  center ;
    width:  300px ;
    color:  black ;
}

.sportsEquipmentContainer{
    background-color:  white ;
    margin-bottom:  50px ;
    border: 1px solid #e7e4e2 ;
    box-shadow:  2.5px 2.5px #e7e4e2 ;

}

.sportsEquipmentTxt{
    font-size:  23px ;
    line-height:  30px ;
    text-align:  justify ;
    margin-top:  20px ;
    font-family:  Cambria, Georgia, serif ;
}

.hygieneProjectHR{
    text-align:  center ;
    width:  300px ;
    color:  black ;
}

.hygieneProjectContainer{
    background-color:  white ;
    margin-bottom:  50px ;
    border: 1px solid #e7e4e2 ;
    box-shadow:  2.5px 2.5px #e7e4e2 ;
}

.hygieneProjectTxt{
    font-size:  23px ; 
    line-height:  30px ;
    text-align:  justify ;
    margin-top:  20px ;
    font-family:  Cambria, Georgia, serif ;
}
.libraryPic{
    height: 335px;
    width: 467px;
    display: block;
}
.picturesMobile{
    display: block;
}

@media (max-width: 768px) {
    h2{
        font-size: 20px;
    }

    h1{
        font-size: 20px;
    }
    h3{
        font-size: 18px;
    }
    .schoolSponsorshipProgramTxt{
        font-size: 14px;
    }
    .libraryPic{
        display: none;
    }
    .picturesMobile{
        display: none;
    }
}