
.vefText {
    padding-left: 15px;
    font-size: 24px;
    color: #41a2d8 !important;
    font-family: "Open Sans", sans-serif;
    font-weight: bolder;
}

@media only screen and (max-width: 500px) {
    .vefText {
        font-size: 14px;
    }
  }

.vefImg {
    height: 64px; 
}

.navBarDropdown{
    margin-top: -1px;
    content: '';
}

.caret {
    display: none;
    content: none
}

.donateButton {
    margin: "auto";
    align-items: center;
    text-align: center;
    display: "block";
    background-color:"#369FF4" !important;
}

.donateButton:hover {
    background-color:#41A3D8 !important;
}

#our-work-tab::after {
    display: none;
    content: none;
}


@media only screen and (max-width: 500px) {
    .vefImg {
        height: 40px;
    }
  }