.mainContainer{
    background-color: #f7f5f4;
    padding-bottom: 50px;
}
body{
    color: #495057;
}
.mainParagraph{
    font-size: 18px;;
    line-height: 30px;
    text-align: justify;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

@media (max-width: 768px) {
    .mainParagraph{
        font-size: 14px;
        line-height: 20px;
    }
}