body{
    color: #495057;
}
.mainParagraph{
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
}

@media (max-width: 768px) {
    .mainParagraph{
        font-size: 14px;
        line-height: 20px;
    }
}