.backgroundPicture {
    height: 65vh;
    /* original was 75 */
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.pcView{
    display: block;
}
.mobileView{
    display: none;
}

.iconPictures {
    height: 170px;
}

.whatWeDo{
    font-size: 25px;
}

.mainAreas{
    font-size: 38px;
}





.reactPlayer {
    position: absolute;
    top: 0;
    left: 0;
}

.playerWrap {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    margin-bottom: 50px;
}
.visionContainer {
    padding-left: 5% ;
    padding-right: 5% ;
    background-color: #f8f8f8 ;
    display: flex ;
    flex-direction: column ;
    text-align: center ;
    align-items: center ;
    justify-content: center ;
}

.ourVisionTxt {
    display: flex ;
    flex-direction: column ;
    text-align: center ;
    align-items: center ;
    margin-top: 40px ;
}

.ourVisionHR {
    text-align:  center ;
    width:  300px ;
    color:  black ;
}
.ourVisionParagraph {
    font-family:LexiaThin;
    color:#6C757D;
    font-size:17pt;
    text-align:center;
}

.ourVisionHRTwo {
    text-align: center ;
    width: 300px ;
}

.aboutUsContainer {
    text-align: center ;
    border: 1px solid #e7e4e2 ;
    box-shadow:  2.5px 2.5px #e7e4e2 ;
}

.aboutUsTxt {
    font-family: Roboto ;
    font-weight: bold ;
    font-size: 35px ;
    text-align: centered ; /* I added this one to center the text*/
}

.founderParagraph {
    color: #596168;
    font-size:  19px ;
    line-height:  28px ;
    text-align:  justify ;
    font-family:  Georgia ;
    margin-top:  30px ;
}

.joinContainer { 
    /*didn't put in, already has a className */
    font-family: 'Merriweather', serif ;
    background-color: #f7f4f2 ;
    text-align: centered ;
}

.joinHR {
    text-align:  center ;
    width:  300px ;
    color:  black ;
}

.smileWithHope {
    font-family: Quicksand, sans-serif;
    color:#6C757D;
    font-size:17pt;
    text-align:center;
}

.joinDiv {
/* line 140 */
}
.joinFoundation{
    text-align: center;
    font-size: 2.5rem;
}

.individualVolunteerDiv {
    display:  flex ;
    flex-direction:  column ;
    align-items:  center ;
    justify-content:  center ;
}

.individualVolunteerCard {
    width: '22rem';
    font-size: 30px ;
    font-family: Arial ;
    font-weight: bold ;
    margin-top: 20px ;
    text-align: center ;
    justify-content: center ;
    border-color: #007bff;
    background-color: #007bff;
}

.individualVolunteerText{
    font-size: 18px;
}

.centerButton{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.applyButton {
    margin: "auto";
    align-items: center;
    text-align: center;
    display: "block";
    background-color:"#369FF4";
}
.applyButton:hover {
    background-color:#41A3D8;
}

.threeBoxes{
    background-color: white;
    min-height: 700px;
    border: 1px solid #e7e4e2;
    box-shadow: 2.5px 2.5px #e7e4e2;
}

.individualVolunteerTxt {
    display:  flex ;
    text-align: center ;
    justify-content: center ;
}
.individualVolunteerParagraph {
    /* line 155 --> already has a className */
}

.corporateDonorDiv {
    display:  flex ;
    flex-direction:  column ;
    align-items:  center ;
    justify-content:  center ;
}

.corporateDonorCard{
    width: '22rem';
    font-size: 30px ;
    font-family: Arial ;
    font-weight: bold ;
    margin-top: 20px ;
    text-align: center ;
    justify-content: center ;
    border-color: #007bff;
    background-color: #007bff;

}

.corporateDonorTxt{
    display:  flex ;
    text-align: center ;
    justify-content: center ;
    color: #41A3D8;
}

.corporateDonorText{
    font-size: 18px;
}

.corporateDonorParagraph{
    /* class name already exists line 197 */
}

.educationVolunteerDiv {
    display:  flex ;
    flex-direction:  column ;
    align-items:  center ;
    justify-content:  center ;
}

.centerTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    background-color: #f8f8f8;
}

.educationVolunteerCard {
    width: '22rem';
    font-size: 30px ;
    font-family: Arial ;
    font-weight: bold ;
    margin-top: 20px ;
    text-align: center ;
    justify-content: center ;
    border-color: #007bff;
    background-color: #007bff;
}


.educationVolunteerTxt {
    display:  flex ;
    text-align: center ;
    justify-content: center ;
    color: #41A3D8;
}
.educationVolunteerText{
    font-size: 18px;
}

.educationVolunteerParagraph {
    /* line 241 - className already exists */
}

@media (max-width: 768px) {
    .backgroundPicture{
        width: auto;
        height: 20vh;
        background-image: url(${frontPagePic});
        margin-left: -50px;
        margin-top: 50px;
    }
    .columns{
        width: 100%;
    }
    .pcView{
        display: none;
    }
    .mobileView{
        display: block;
    }
    .whatWeDo{
        font-size: 15px;
    }
    .mainAreas{
        font-size: 25px;
    }
    .iconPictures{
        height: 100px;
    }
    .ourVisionParagraph{
        font-size: 15px;
    }
    .joinFoundation{
        font-size: 25px;
    }
    .individualVolunteerCard{
        width: 15rem;
        font-size: 20px;
    }
    .corporateDonorCard{
        width: 15rem;
        font-size: 20px;
    }
    .educationVolunteerCard{
        width: 15rem;
        font-size: 20px;
    }
    .individualVolunteerText{
        margin-top: 30px;
        font-size: 12px;
    }
    .corporateDonorText{
        margin-top: 30px;
        font-size: 12px;
    }
    .educationVolunteerText{
        margin-top: 30px;
        font-size: 12px;
    }
    .threeBoxes{
        min-height: 400px;
    }
}