.bcChapterWriting{
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-top: 20px;
}
@media (max-width: 768px) {
    .bcChapterWriting{
        font-size: 14px;
        line-height: 20px;
    }
}