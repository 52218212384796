.mainWorkContainer{
    margin-top: 60px;
    padding-bottom: 50px;
    background-color: #f8f8f8;
}

.centerTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    background-color: #f8f8f8;
}

.ourWorkContainer {
    display: flex ;
    flex-direction: column ;
    text-align: center ;
    align-items: center ;
    margin-top: 40px ;
    font-size: 38px ;
}

.ourWorkParagraph {
    font-family:LexiaThin;
    color:#6C757D;
    font-size:17pt;
    text-align:center;
    align-items: center ;
    justify-content: center ;
}

.whyTxt {
    font-size:  18px ;
    line-height:  30px ;
    text-align:  justify ;
    font-family:  Georgia ;
}

.ourWorkParagraph2{
    align-items: center ;
    justify-content: center ;
    padding-left: 15% ;
    padding-right: 15% ;
    font-size:  18px ;
    line-height:  30px ;
    text-align:  justify ;
    font-family:  Georgia ;
}

.ourWorkParagraph3{
    align-items: center ;
    justify-content: center ;
    /* padding-left: 15% ;
    padding-right: 15% ; */
    font-size:  12px ;
    line-height:  20px ;
    text-align:  justify ;
    font-family:  Georgia ;
}

.ourWorkHeader{
    justify-content: left !important;
    text-align:  left !important;
    align-items: left !important;
    display: flex !important;

}

.workContainer {
    padding-left: 5% ;
    padding-right: 5% ;
    background-color: #f8f8f8 ;
    display: flex ;
    flex-direction: column ;
    text-align: center ;
    align-items: center ;
    justify-content: center ;
}

.ourWorkTxt {
    display: flex ;
    flex-direction: column ;
    text-align: center ;
    align-items: center ;
    margin-top: 40px ;
}

.ourWorkHR {
    text-align:  center ;
    width:  300px ;
    color:  black ;
}

.storiesFromFieldTxt {
    font-size:  20px ;
    line-height:  30px ;
    text-align:  justify ;
    font-family:  Georgia ;
}

.howWeWorkTxt {
    margin-left: 20px;
}

.provingOurImpactTxt {
    margin-left: 20px;
}